import React from 'react'

const canvas = () => {


  return (
    <>
      <canvas id="gradient-canvas"></canvas>
    </>
  )
}
export  default canvas;
